import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import makeStyles from "@mui/styles/makeStyles"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import { CopyBlock } from "react-code-blocks"
import Chip from "@mui/material/Chip"

import { RepositoryMetrics } from "repository-metrics"

import MenuBookIcon from "@mui/icons-material/MenuBook"
import GitHubIcon from "@mui/icons-material/GitHub"
import ChatIcon from "@mui/icons-material/Chat"
import YouTubeIcon from "@mui/icons-material/YouTube"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header.de"
import ImageBanner from "../images/universe-4609408.93fbf14b-clean.jpg"
import ImageWavesDark from "../images/bg-waves-dark.png"
import ContentBox from "../components/contentBox"
import AccordionBox from "../components/accordionBox"
import codeBlockTheme from "../theme/codeBlockTheme"
import CodeExampleRestreamer from "../components/codeExampleRestreamer"

import ButtonYellow from "../components/buttonYellow"

import Player from "../components/player"
import RestreamerVideo from "../assets/restreamer.mp4"
import RestreamerPoster from "../assets/restreamer.jpg"

const useStyles = makeStyles(theme => ({
  TabTitel: {
    fontWeight: 500,
  },
  CodeBlock: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: 8,
    width: "100%",
    fontSize: '1rem'
  },
  CodeTab: {
    padding: "10px 0px 5px 0px",
  },
}))

function DockerCmd() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%", marginBottom: "-.5em", paddingRight: ".4em" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 2, borderColor: "rgba(0,0,0,.45)" }}>
          <TabList
            onChange={handleChange}
            aria-label="docker-tab"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab label="AMD64/ARMv7/ARM64" value="1" />
            <Tab label="Raspberry Pi (ARMv7/v8)" value="2" />
            <Tab label="NVidia CUDA (AMD64)" value="3" />
            <Tab label="Intel VAAPI (AMD64)" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_multiarch}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> gewährt den Zugriff auf
              angeschlossene Geräte auf dem Host-System.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="2" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_pi}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> gewährt den Zugriff auf
              angeschlossene Geräte auf dem Host-System.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="3" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_cuda}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> gewährt den Zugriff auf
              angeschlossene Geräte auf dem Host-System.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="4" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_vaapi}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> gewährt den Zugriff auf
              angeschlossene Geräte auf dem Host-System.
            </Typography>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

function Datasheet() {
  const classes = useStyles()
  const [value, setValue] = React.useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%", marginBottom: "-.5em", paddingRight: ".4em" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 2, borderColor: "rgba(0,0,0,.45)" }}>
          <TabList
            onChange={handleChange}
            aria-label="datasheet-tab"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab label="Videoquellen" value="1" />
            <Tab label="Videoziele" value="2" />
            <Tab label="Media-Server" value="3" />
            <Tab label="Wiedergabe" value="4" />
            <Tab label="API" value="5" />
            <Tab label="Sonstiges" value="6" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Network Video
            </Grid>
            <Grid item xs={12} md={9}>
              RTP, RTMP/S, RTSP/S, HTTP/S, SRT
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              USB Device
            </Grid>
            <Grid item xs={12} md={9}>
              Linux (MacOS, Windows mit Docker Desktop/Linux-Virtualisierung)
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Raspi-Cam
            </Grid>
            <Grid item xs={12} md={9}>
              Raspberry Pi3 und aktueller
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Social Media Plattformen
            </Grid>
            <Grid item xs={12} md={9}>
              YouTube Live, Facebook Live, Twitter, LinkedIn, TikTok, viele
              weitere.
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Streaming-Server
            </Grid>
            <Grid item xs={12} md={9}>
              Wowza Streaming Engine, Red5, Nimble Streamer, Ant Media Server
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Netzwerk Endpunkte
            </Grid>
            <Grid item xs={12} md={9}>
              RTP, RTMP/S, RTSP/S, HTTP/S, SRT
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              HLS Server (HTTP/S)
            </Grid>
            <Grid item xs={12} md={9}>
              Konfigurierbare Größen von Chunks und Wiedergabelisten
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              RTMP/S Server
            </Grid>
            <Grid item xs={12} md={9}>
              Konfigurierbarer App-Endpunkt und Sicherheits-Token (abrufen und
              empfangen)
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="4">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Video-Player
            </Grid>
            <Grid item xs={12} md={9}>
              Intergrierter Video.js Player
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Playersite
            </Grid>
            <Grid item xs={12} md={9}>
              Vorgefertigte HTML-Landingpage zum Selbsthosten.
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="5">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Process
            </Grid>
            <Grid item xs={12} md={9}>
              FFmpeg Ein- und Ausgangskonfiguration, Grenzwerte, Bereinigung,
              Fortschritts-, Metadaten, Zustände und historische Protokolle.
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Möglichkeiten
            </Grid>
            <Grid item xs={12} md={9}>
              Alle erkannten FFmpeg-Fähigkeiten auflisten
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Metriken
            </Grid>
            <Grid item xs={12} md={9}>
              Abfrage der gesammelten Metriken in konfigurierbaren Intervallen
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Sitzung
            </Grid>
            <Grid item xs={12} md={9}>
              Übersicht aller aktiven und vergangenen Sitzungen
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              In-Memory / Disk Filesystem
            </Grid>
            <Grid item xs={12} md={9}>
              Verwalten von Dateien
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              RTMP
            </Grid>
            <Grid item xs={12} md={9}>
              Liste aller derzeit veröffentlichten Streams
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Konfiguration
            </Grid>
            <Grid item xs={12} md={9}>
              Konfiguration der Anwendung
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Protokollierung
            </Grid>
            <Grid item xs={12} md={9}>
              Abrufen der letzten Protokollzeilen der Anwendung
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="6">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Bandbreiten- und Publikumskotnrolle
            </Grid>
            <Grid item xs={12} md={9}>
              Begrenzungen der HTTP-Bandbreite (Mbit/s) und aktive
              HTTP/S-Sitzungen (aktuelle Betrachtende)
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              HTTPS/TLS
            </Grid>
            <Grid item xs={12} md={9}>
              Let’s Encrypt Unterstützung für HTTPS/RTMPS
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Videolizenzierung
            </Grid>
            <Grid item xs={12} md={9}>
              Creative Commons
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Multilingual
            </Grid>
            <Grid item xs={12} md={9}>
              Verwenden Sie Ihre eigene Muttersprache, oder unterstützen Sie
              mehrere verschiedene Sprachen mit Leichtigkeit.
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
const IndexPage = () => (
  <Layout>
    <Seo
      title="Open Source Streaming Server ⇒ Live Streaming Interface"
      description="Videostreaming auf der Website  ⇒ ohne Streaming-Anbieter ✓ Open Source ✓ Kostenlos ✓ Alle IP-Cams ✓ Alle Betriebssysteme  ⇒ mehr erfahren!"
      keywords="open source, live, streaming"
      lang="de"
    />
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#fff url(${ImageBanner}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <Header siteName="home" rootPath="/de/" />
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          marginTop={10}
          marginBottom={10}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            width="100%"
          >
            <Typography variant="h1" fontSize={{ xs: "3rem", sm: "4rem", md: "5rem" }}>
              RESTREAMER
            </Typography>
            <Typography variant="body" fontSize="2rem">
              Ein einfach zu bedienender Open-Source-Videoserver mit moderner
              Benutzeroberfläche und FFmpeg-API für kostenloses Video-Streaming
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <RepositoryMetrics
                owner="datarhei"
                repo="restreamer"
                theme="dark"
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{
                  border: "1px solid #b2bec3",
                  borderRadius: 5,
                  padding: "5px 8px 5px 8px",
                  background: "#394144",
                }}
              >
                <MuiLink
                  href="https://github.com/datarhei/restreamer/blob/2.x/LICENSE"
                  target="_blank"
                  aria-label="Apache 2.0 license"
                  fontSize=".95rem"
                  color="#dfe6e9"
                >
                  Apache 2.0 license
                </MuiLink>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            width="100%"
          >
            <Player
              source={RestreamerVideo}
              poster={RestreamerPoster}
              type="video/mp4"
              control={true}
            />
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          marginBottom={11}
        >
          <ContentBox
            titel="Multi-Streaming"
            description="Streamen Sie Videos live auf jede Videoplattform oder Webseiten!"
            variant="teaser"
          />
          <ContentBox
            titel="Easy Konfiguration"
            description="Der Assistent unterstützt Neulinge und erleichertert die Einrichtung."
            variant="teaser"
          />
          <ContentBox
            titel="Keine Lizenzkosten"
            description="Open-Source-Software — frei für private und kommerzielle Nutzung."
            variant="teaser"
          />
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            id="installation"
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            INSTALLATION MIT DOCKER
          </Typography>
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            Verschiedene CPU und GPU Architekturen! Das Restreamer Docker-Image
            ist einfach zu installieren und läuft auf Linux, MacOS, Windows
            sowie auf Raspberry Pi und vielen Einplatinenrechnern. Die
            Konfiguration ist unkompliziert und mit ein paar einfachen Schritten
            erledigt. Der erste Live-Stream ist in einer Minute bereit für
            Websites, externen Streaming-Dienst, soziale Netzwerke, YouTube oder
            direkt auf der fertige internen Mikro-Webseite!
          </Typography>
          <ContentBox maxWidth={1000} description={<DockerCmd />} />
          <Stack paddingTop={5} paddingBottom={1}>
            <Typography
              variant="h3"
              fontSize="2rem"
              color="#333"
              fontWeight={900}
            >
              DOKUMENTATION & HILFE
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            alignItems="center"
            spacing={{ xs: 2, md: 8 }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://docs.datarhei.com/restreamer/v/de/"
                marginBottom={-1.5}
                aria-label="Handbuch"
              >
                <MenuBookIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://docs.datarhei.com/restreamer/v/de"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Handbuch
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://www.youtube.com/user/datarhei"
                marginBottom={-1.5}
                aria-label="Video Anleitungen"
              >
                <YouTubeIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://www.youtube.com/user/datarhei"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Video Anleitungen
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://github.com/datarhei/restreamer/issues"
                marginBottom={-1.5}
                aria-label="Ticket/Issue"
              >
                <GitHubIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://github.com/datarhei/restreamer/issues"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Ticket/Issue
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://discord.gg/33JCDkPmmj"
                marginBottom={-1.5}
                aria-label="Discord Chat"
              >
                <ChatIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://discord.gg/33JCDkPmmj"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Discord Chat
              </MuiLink>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#222`,
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} textAlign="center" marginBottom={2}>
            <Typography
              id="eigenschaften"
              variant="h2"
              fontSize="2.5rem"
              color="#fff"
              fontWeight={900}
            >
              EIGENSCHAFTEN
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center" marginBottom={{xs: 3, md: 10}}>
            <Typography
              id="features"
              variant="body"
              fontSize="1.2rem"
              color="#fff"
              fontWeight={300}
            >
              Der Restreamer ist eine komplette Streaming-Server-Lösung zum
              Selbst hosten. Laden Sie Ihren Livestream auf YouTube, Twitch,
              Facebook, Vimeo oder andere Streaming-Lösungen wie Wowza.
              Empfangen Sie Videodaten von OBS und veröffentlichen Sie diese mit
              dem internen RTMP-Server. Die moderne Bedienoberfläche ist sehr
              übersichtlich und leicht zu verstehen. Einsteiger werden durch
              Assistenz- und Testsysteme unterstützt und finden sich sofort in
              der Komplexität eines Videoservers zurecht. Profis schätzen die
              schnellen und spontanen Konfigurationsmöglichkeiten für komplexe
              Einstellungen der Anwendungen im Arbeitsalltag. Darüber hinaus
              hilft die Bandbreitenbegrenzung bei der Kalkulation der
              Traffic-Kosten, um finanzielle und technische Risiken zu
              reduzieren. Sie ist DSGVO-konform, ohne Drittanbieter und ohne
              Speicherung von Publikumsdaten. Restreamer bietet 100 % Kontrolle
              über die Videodaten zu jeder Zeit. Und das Beste daran ist, dass
              Restreamer freie Open-Source-Software ist, keine laufenden
              Lizenzkosten erzeugt und für die private und kommerzielle Nutzung
              freigegeben ist!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center" marginBottom={{xs: 2, md: 0}}>
            <StaticImage
              src="../images/restreamer-clean-interface.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zeigt das Startbild der Restreamer-Benutzeroberfläche."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Moderne Bedienoberfläche
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Der Restreamer bietet ein modernes Admin-Panel im Browser für
              Streaming-Server-Neulinge oder für Benutzer, denen der
              Funktionsumfang eines Streaming-Servers einfach zu gross ist. Die
              grafische Oberfläche verfügt über eine übersichtliche Menüführung
              und hat einen Hauptbildschirm mit den wichtigsten Elementen.
              Direktes Feedback in der Benutzeroberfläche mit
              Fehlerbeschreibungen beschleunigt die Konfiguration und vermeidet
              Fehler bei der Konfiguration. Der Expertenmodus erweitert den
              Funktionsumfang, um den Anforderungen von Profis gerecht zu
              werden.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Videokanäle verwalten" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Integrierter Videoplayer" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Stream Statistik" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Publikums Statistik" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bandbreiten Informationen" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Systemüberwachung" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Öffentliche Webseite" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Output Kontrolle" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Experten Modus" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Multiple Videoquellen
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Der Restreamer kann verschiedene USB-, Netzwerk- oder
              Virtuelle-Videoquellen verwalten. Darüber hinaus verarbeitet der
              eingebaute RTMP-Server Videosignale von externer Software wie OBS
              oder Hardware-Video-Encodern von Blackmagic und Elgato. Als
              absolutes Highlight unterstützt der datarhei Restreamer
              Low-Latency-Video mit einem internen SRT-Server.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Netzwerk Kameras" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="USB Videosignale" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RaspiCam" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="OBS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Blackmagic" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Video-Software" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Hardware-Encoder" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="WOWZA" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HLS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DASH" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <StaticImage
              src="../images/restreamer-multiple-sources.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zeigt die Einrichtung einer Videoquelle."
              width={500}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6} textAlign="center">
            <StaticImage
              src="../images/restreamer-multi-streaming.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zeigt eine Auswahl möglicher Video-Ziele."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Multi-Streaming
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Mit den Publication-Services kann der Restreamer Videos überall
              hin streamen. Es sind viele Module für beliebte Videoplattformen
              und soziale Netzwerke verfügbar. Die zahlreichen Videoprotokolle
              garantieren die Übertragung an Rundfunkstationen sowie Videosoft-
              und Hardware. Darüber hinaus ermöglicht die vollständig
              dokumentierte API die Einbindung von Videostreams in Ihre
              Software-Anwendungen und Systemlandschaften mit einfachsten
              Mitteln ohne umfangreiche Softwareentwicklung.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="YouTube" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Social media" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="CDN" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HLS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DASH" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="API" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Red5" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="WOWZA" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Ant Media" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Open-Source Video-Player
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Video.js ist ein kostenloser Open-Source-Webvideoplayer, der
              weltweit von Profis genutzt wird und der Standardplayer im
              Restreamer ist. Er unterstützt HTML5-Video und alle moderne
              Streaming-Formate. Im Restreamer kann jeder HLS-fähige Player, wie
              JW-Player, Bitmovin, Clappr oder der Theo-Player zum Einsatz
              kommen. Als Besonderheit können Videoproduzierende mit dem
              datarhei Restreamer exklusiven CC-Plugin eine Lizenz direkt im
              Videostream veröffentlichen und der Öffentlichkeit so verschiedene
              Nutzungsrechte an dem Werk einräumen.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Creative Commons Lizenzen" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="IMA-Plugin" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="CSS Styling" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="API" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Open-Source-Bibliothek" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="JW-Player" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bitmovin" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <StaticImage
              src="../images/restreamer-player.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zeigt den Konfigurator für den Video-Player."
              width={500}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6} textAlign="center">
            <StaticImage
              src="../images/restreamer-tons-of-functions.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zeigt die vielen Einstellmöglichkeiten und die Prozessdetails."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Zahlreiche Funktionen
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Der Restreamer enthält zahlreiche durchdachte Funktionen, die den
              Streaming-Alltag erleichtern und die Anwendungsmöglichkeiten des
              Programms erheblich erweitern. Logdateien helfen, Fehler zu
              analysieren, den ordnungsgemäßen Betrieb zu überwachen oder
              Systeme zu verbessern. Darüber hinaus liefern Logdateien
              Informationen über die Videoverarbeitung. Die Protokolldaten
              helfen dabei einen Videostream anzupassen und zu optimieren.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Verschiedene Sprachen" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Assistens-System" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Debug-Report" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Systemprotokollierung" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HDMI" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="VOD-Replay" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DVR" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Refferer-Banliste" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Experten Modus" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Audio Muxen" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Video-Snapshot" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HTTPS Let's Encrypt" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Puklikums-Limitation" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bandbreiten-Kontrolle" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP Sicherheitstoken" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Speicher Unterstütztung" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="GPU-Encoding" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP-Server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT-Server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP-Server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="FFmpeg REST API" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            id="demo"
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            RESTREAMER DEMO
          </Typography>
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            <strong>Benutzername:</strong> admin <strong>Passwort:</strong> demo
          </Typography>
          <ButtonYellow
            variant="form_dark"
            titel="Demo öffnen"
            href="https://demo.datarhei.com/ui"
            target="blank"
          />
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: "#333",
        borderTop: "3px solid #111",
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} textAlign="center" marginBottom={10}>
            <Typography
              variant="h2"
              fontSize="2.5rem"
              color="#fff"
              fontWeight={900}
            >
              INSIDE RESTREAMER
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} textAlign="center">
            <StaticImage
              src="../images/restreamer-stack_de.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Restreamer inside - The Stack."
              width={1000}
            />
          </Grid>
          <Grid item xs={12} textAlign="center" marginTop={5}>
            <Typography
              variant="body"
              fontSize="1.2rem"
              color="#fff"
              fontWeight={300}
            >
              datarhei Restreamer ist die zentrale Schnittstelle zur Abbildung
              von AV-Prozessen. Er ist verantwortlich für das Prozess-design und
              -verwaltung und stellt alle notwendigen Schnittstellen für den
              Zugriff auf Ihre Videoinhalte zur Verfügung. Die Steuerung für
              FFmpeg kann alle verwendeten Funktionen zuverlässig und lauffähig
              halten, ohne dass sich Softwareentwickler darum kümmern müssen.
              Darüber hinaus schützt die Prozess- und Ressourcenbegrenzung für
              alle FFmpeg-Prozesse das Host-System vor Überlastung. Das
              Gesamtsystem ermöglicht den direkten Zugriff auf die aktuellen
              Prozesswerte (CPU, RAM) und die vollständige Kontrolle der
              Ressourcen und der Systemauslastung mit statistischem Zugriff auf
              Prozessdaten sowie aktuelle und Archivdaten.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat right center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            DATASHEET
          </Typography>
          <ContentBox maxWidth={1000} description={<Datasheet />} />
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            Der datarhei Restreamer ist ein kostenloser Video-Server zum selber
            hosten und bietet intelligentes kostenloses Video-Streaming in
            Echtzeit.
          </Typography>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        background: `#fff url(${ImageBanner}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          paddingBottom={5}
        >
          <Typography
            id="faq"
            variant="h2"
            fontSize="2.5rem"
            fontWeight={900}
            textAlign="center"
            textTransform="uppercase"
          >
            Frequently Asked Questions
          </Typography>
        </Stack>
        <AccordionBox
          titel="Was ist der Streaming-Server datarhei Restreamer?"
          description="Der Restreamer ist eine vollständige Streaming-Server-Lösung zum selbst hosten. 
          Laden Sie Ihren Live-Stream auf YouTube, Twitch, Facebook, Vimeo oder andere Streaming-Lösungen 
          wie Wowza hoch. Empfangen Sie Videodaten von OBS und veröffentlichen Sie diese über den internen 
          RTMP-Server. Optisch ansprechende Bedienoberfläche, keine laufenden Lizenzkosten. Volle Kontrolle 
          über die Videodaten zu jeder Zeit. Die Bandbreitenbegrenzung hilft bei der Berechnung der 
          Traffic-Kosten, um finanzielle und technische Risiken zu reduzieren. Es ist DSGVO-konform ohne 
          Drittanbieter und ohne Speicherung von Zuschauerdaten. Das Restreamer Docker-Image ist einfach 
          zu installieren und läuft auf Linux, MacOS und Windows, sowie auf Raspberry Pi und 
          Einplatinencomputern. Einsteiger werden durch Hilfs- und Testsysteme unterstützt und finden sich 
          sofort in der Komplexität eines Video Servers zurecht. Profis schätzen die schnellen und spontanen 
          Konfigurationsmöglichkeiten für komplexe Einstellungen der Anwendungen im Arbeitsalltag. 
          "
        />
        <AccordionBox
          titel="Auf welchen Betriebssystemen kann der Streaming-Server datarhei Restreamer installiert werden?"
          description="Für eine schnelle und sichere Installation empfehlen wir unser Docker Image. Sobald Docker 
          auf dem Host läuft, stehen die Chance sehr gut den Restreamer nutzen zu können. Die Docker Engine ist 
          auf einer Vielzahl von Linux-Plattformen, MacOS und Windows 10 über Docker Desktop und als statische 
          binär Installation verfügbar."
        />
        <AccordionBox
          titel="Welche Voraussetzungen gibt es für die Nutzung vom datarhei Restreamer als Streaming-Server?"
          description="Um offiziellen Support von unserem Team zu erhalten, haben wir eine Reihe von 
          Mindestanforderungen. Diese Anforderungen sind notwendig, damit wir auf wesentliche Systeminformationen 
          zugreifen, ein SLA bereitstellen, Fragen beantworten oder eine Lösung für das Problem anbieten können. 
          Nur Installationen, die diese Mindestanforderungen erfüllen, können von unseren SLAs und unserer 
          kostenpflichtigen Support-Richtlinie abgedeckt werden. Um Umgebung Probleme durch fehlende, veraltete 
          oder nicht übereinstimmende Abhängigkeiten, spezifische Betriebssystem Probleme oder Probleme bei 
          manuellen Installationen zu vermeiden, empfehlen wir dringend die Verwendung unserer offiziellen Docker-Images."
        />
        <AccordionBox
          titel="Kann ein Live-Stream auch ohne jegliche Vorkenntnisse auf dem Streaming Server aktiviert werden?"
          description="Das Assistenzsystem für das Anlegen von Videoquellen im Restreamer hilft Dir eine neue Videoquelle 
          ohne Vorkenntnisse anzulegen. In wenigen Schritten ist das Videosignal so aktiv und kann über ein Publication 
          Service z. B. weiter zu YouTube, auf die Playerseite oder auf die Webseite gestreamt werden."
        />
        <AccordionBox
          titel="Wie viele Schritte werden bei der Installation vom Streaming Server benötigt?"
          description={
            <React.Fragment>
              Der datarhei Restreamer ist, dank des Docker-Images, in wenigen
              Sekunden auf dem Host System installiert, gestartet und über die
              Bedienoberfläche im Browser bereit für die erste Anmeldung. Es ist
              dafür lediglich ein einziges Kommando für die Installation
              notwendig.
              <br />
              <br />
              Voraussetzung ist ein Docker fähiges Host-System, eine
              Internetleitung für den Pull und einen aktuellen Browser.
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Ist der Streaming-Server für das Raspberry Pi geeignet?"
          description={
            <React.Fragment>
              Wenn es einen perfekten Streaming-Server für den beliebten
              Single-Board-Computer Raspberry Pi gibt, dann ist es der datarhei
              Restreamer! Wir empfehlen ein Raspberry Pi 4. Grundsätzlich
              funktioniert es ab dem Raspberry Pi 2 und auch auf einem Raspberry
              Pi Zero. Das Raspi 4 eignet sich wegen der Onboard-GPU am besten
              für Videostreaming mit einem Video-Streaming Server. Grundsätzlich
              gibt es natürlich noch viele andere tolle Single Board Computer
              wie von ODROID, Pine64, das Hummingboard und viele andere. Die
              notwendigen Systemanforderungen sind leicht erklärt. Sobald Docker
              funktioniert, wirst Du den datarhei Restreamer installieren und
              für Live-Streaming verwenden können. Die Anforderungen findest Du
              in den <a href="https://docs.datarhei.">Docs</a>.
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Welche einzelnen Komponenten stellt der Streaming-Server zu Verfügung?"
          description="Der Streaming-Server datarhei Restreamer besteht aus wenigen, übersichtlichen Modulen. 
          Im Prinzip besteht der Restreamer aus Eingangssignal, Verarbeitung, Ausgang und verschiedenen 
          Systemeinstellungen. Auf dem Hauptbildschirm siehst Du immer den aktuellen Videokanal. Unter dem 
          Videoplayer findet sich die Laufzeit, die Bitrate und die Bildwiederholfrequenz. Bei den Publication 
          Services wird immer die Anzahl der Zuschauenden und die aktuelle Bitrate vom Videoausgang in Echtzeit angezeigt. 
          "
        />
        <AccordionBox
          titel="Was sind die Vorteile vom Streaming-Server datarhei Restreamer?"
          description="Mit dem datarhei Restreamer ist es möglich Live-Video-Streaming in Echtzeit auf Webseiten 
          und Social Media Plattformen ohne einen zusätzlichen Streaming-Anbieter für Videohosting zu nutzen — alles 
          ohne monatliche Lizenzkosten für Software oder Kosten für die benötigte Bandbreite. Der datarhei Restreamer 
          ist eine moderne Open Source Videostreaming-Plattform, die sich jeder installieren kann. 
          Ohne einen Videohoster können Videos von Webcams bis hin zu Fernsehprogrammen zuverlässig live übertragen 
          werden. Der datarhei Restreamer hilft privaten und professionellen Videocreators dabei, effizienter zu 
          streamen. Der Restreamer ist ein einfache, aber dennoch leistungsstarker Service zum Livestreaming von 
          Videos. Die vollständig dokumentierte API hilft bei der Softwareentwicklung. datarhei Restreamer lässt 
          sich sehr leicht an jedes Projekt anzupassen."
        />
        <AccordionBox
          titel="Für wen ist die Integration eines Streaming Server wie dem datarhei Restreamer grundsätzlich hilfreich?"
          description="Streaming Server werden immer dann benötigt, sobald live und Video eine Anforderung ist. Der Output 
          wird dann als Livestream bezeichnet. Streaming Server sind dafür ausgelegt Videos an ein großes Publikum zu senden. 
          Im Prinzip ist ein Streaming Server sehr ähnlich eines Webservers, nur dass keine Webseiten ausgeliefert werden, 
          sondern Videos. Es sind auch Anwendungen für Videoclips (VoD) möglich. Der Streaming-Server datarhei Restreamer ist 
          ein Frontend-Modul für den datarhei Core. Der Core kann ganz einfach von Softwareentwicklern in Programme oder 
          Administratoren in Systemlandschaften integriert werden, um seinen Teil für den Transport der Videodaten zu erfüllen. 
          Die Nutzung ist frei für private und kommerzielle Anwendungen."
        />
        <AccordionBox
          titel="Ist der Streaming-Server freie Open Source Software?"
          description="Eine große Besonderheit unseres datarhei Restreamer ist, dass der datarhei Core und der Restreamer 
          kostenloses Open Source Software ist. Der Quellcode ist auf der Softwareplattform GitHub verfügbar. Softwareentwickler 
          sind so in der Lage, Anpassungen durchzuführen oder Module zu ergänzen. Die FOSS GmbH aus der Schweiz steht hinter der 
          Software und kann für Enterprise Anwendungen hilfreich zur Seite stehen, weil wir unsere Software am besten kennen."
        />
        {/* <AccordionBox
          titel="Welche Protokolle unterstützt der Videostreaming Server?"
          description="Durch die Nutzung von FFmpeg im datarhei Core kann der Restreamer grundsätzlich alle Protokolle von 
          FFmpeg. Wir haben die aktiven Protokolle aber auf einen sinnvollen Bereich eingegrenzt. Ohne größere Anpassungen 
          funktionieren die Protokolle: Apple HLS, RTSP, RTMP(E,S,PT,TE,TS), SRT, MPEG-TS, UDP, Framebuffer (HDMI) und Raw."
        />
        <AccordionBox
          titel="Welche Videoformate kann der Open-Source Streaming-Server datarhei Restreamer streamen?"
          description="Da der datarhei Core mit FFmpeg läuft, kann der Restreamer alle Formate von FFmpeg empfangen und 
          ausgeben. Das sind über 50 Stück! Wir haben das System jedoch so eingestellt, dass die üblichen Industriestandards 
          für Video (H.264 (x264), H.265 (x265), VP9) und Audio (AAC, MP3, Opus) auf jeden Fall funktionieren. Mit ein wenig 
          Konfiguration am datarhei Core könne auch die deaktivierten FFmpeg Formate auf dem Restreamer zum Einsatz kommen. 
          Hierfür musst Du FFmpeg mit den benötigten Formaten neu kompilieren. "
        />
        <AccordionBox
          titel="Kann der Streaming-Server Restreamer Videos speichern? (Video on Demand)?"
          description="Für die Speicherung von Videoclips (VoD) gibt es in den Systemeinstellungen verschiedene Möglichkeiten. 
          Der Streaming-Server unterstützt lokale Speicher oder Netzlaufwerke."
        />
        <AccordionBox
          titel="Ist der Streaming-Server von datarhei DVR fähig?"
          description="DVR steht für Digital Video Recorder und ist eine besondere Art des Speicherns. Wenn das Publikum etwas 
          verpasst hat, kann es im Player des datarhei Restreamer's zurückspulen. Von den Systemeinstellungen hängt es ab, wie 
          lange das möglich ist. Die Videos werden im RAM gespeichert. Das heißt je größer der RAM Speicher, desto länger ist 
          es möglich das Video zu speichern. Wie lange der Speicher nicht voll läuft, hängt wiederum von der Bitrate des 
          Videostreams ab. Die Werte werden in den Systemeinstellungen für das Host-System angepasst."
        />
        <AccordionBox
          titel="Gibt es einen Videoplayer für den Streaming-Server datarhei Restreamer?"
          description="Der Restreamer bringt einen HTML5 Videoplayer mit, der sofort einsatzfähig ist. Es ist der weltweit 
          beliebtesten Open-Source-HTML5-Player Video.js. Mit einer einfachen Konfiguration im Restreamer kann jeder zu 
          seinem Lieblingsplayer wechseln."
        />
        <AccordionBox
          titel="Kann ich den Streaming-Server ohne spezielles Fachwissen nutzen?"
          description="Wir haben bei der Entwicklung vom Restreamer sehr viel Wert auf Einfachheit und Übersichtlichkeit gelegt. 
          Einsteiger werden durch Hilfs- und Testsysteme unterstützt und finden sich sofort in der Komplexität eines Video-Servers zurecht."
        />
        <AccordionBox
          titel="Kann ich mit dem Streaming-Server von datarhei kostenlos Live-Streaming betreiben?
          description="Unser Versprechen ist Live-Streaming auf Webseiten ohne zusätzliche Dienstleister wie einem Video-Provider für 
          Videohosting. Das ist sofort verfügbar — kostenlos und ohne Werbung. Die Hardware für die Installation, die Internetleitung 
          und das Videosignal musst Du natürlich zur Verfügung stellen."
        />
        <AccordionBox
          titel="Kann ich kostenloses HTTPS mit Let’s Encrypt auf dem Streaming Server verwenden?"
          description="Mit wenigen Klicks ist der Wechsel auf HTTPS durchgeführt. Wir haben den Prozess extrem einfach gemacht. Du 
          findest eine Schnellanleitung auf YouTube und eine Anleitung im Handbuch. Kurz gesagt. Domain eintragen — SSL Ports forwarden 
          - Haken aktivieren - Speichern - Fertig!"
        />
        <AccordionBox
          titel="Wie kann ich meinen Live-Stream eine Creative Commons Lizenz geben?"
          description="Der Streaming-Server Restreamer hat etwas Einmaliges, was kein andere Streaming Server der Welt bietet. Jedes 
          Videosignal im Restreamer kann mit einer der verschiedenen Standardlizenzverträge von Creative Commons belegt werden. 
          Videoproduzenten können so der Öffentlichkeit auf einfache Weise Nutzungsrechte an den Live-Streams einräumen. Auf diese 
          Weise entstehen Freie Inhalte. Freie Inhalte, sind wichtig für Menschen, die kein Geld für Texte, Bilder, Musik usw. ausgeben 
          können oder wollen. Außerdem dürfen Inhalte unter bestimmten CC-Lizenzen verändert und weiterverarbeitet werden. "
        />
        <AccordionBox
          titel="Was ist das Besondere am Streaming Server von datarhei?"
          description={
            <React.Fragment>
              Der Streaming-Server datarhei Restreamer ist in vielerlei Hinsicht anders als andere Streaming Server. Core 
              und Restreamer sind nicht auf andere vorhandene Produkte gesetzt, sondern wurden vollständig mit jeder Zeile 
              Programmiercode über Jahre von Streaming Spezialisten aus Deutschland und der Schweiz entwickelt. Je nachdem, 
              was für ein Anwendungsfall im Raum steht, fallen andere Besonderheiten auf. 
              <br />
              <br />
              <strong>Endanwendender:innen</strong> vom Restreamer, die einen einfachen Streaming-Server benötigen, werden 
              die unkomplizierte Installation des Docker Images, die einfache Bedienoberfläche und Assistenzsysteme zu schätzen 
              wissen. Kleine Feinheiten wie die Creative Commons Lizenzen und kostenloses HTTPS mit Let’s Encrypt und die 
              fertige Landingpage mit dem Videoplayer halten die Hürden sehr niedrig. Komplizierten Einstellungen sind nicht 
              notwendig, um direkt mit dem Streaming zu starten!
              <br />
              <br />
              <strong>Professionelle Endanwender:innen</strong>  wissen den großen Funktionsumfang des Streaming Servers zu 
              schätzen. Der Expertenmodus erweitert den Streaming-Server mit allem, was ein Profi im Live-Streaming Alltag 
              von einem Streaming Server erwartet! Schnittstellen über SRT und HDMI ermöglichen den Einsatz für professionelles Broadcasting.
              <br />
              <br />
              <strong>Administratoren:innen</strong> sind moderne Mechaniker. Wie Mechaniker in der Werkstatt mögen sie den Restreamer 
              wegen des datarhei Core — dem Motor des datarhei Restreamers. Die plattformunabhängige Integration auf dem Server ist 
              mit dem Docker Image einfach und nahezu beliebig skalierbar. Die Möglichkeit, vorhandene Monitoringsysteme zu verwenden 
              und sichere Anmeldeverfahren (SSO) über Auth0 zu aktivieren, sind klare Signale, die für den datarhei Restreamer sprechen! 
              Das Open Source Lizenzmodell reduziert die laufenden Kosten auf ein Minimum.  <br />
              <br />
              <strong>Softwareentwickler:innen</strong> llieben die FFmpeg API vom datarhei Core und das REACT Frontend vom Restreamer. 
              Der Core ist der Motor für den Streaming-Server und ermöglicht einfache, zuverlässige und sichere Softwareentwicklung für 
              jede Art von Videosoftware. <br />
              <br />
              Egal in was für eine Anwendung der Core oder Restreamer zum Einsatz kommt. Der  Support der FOSS GmbH bietet Zuverlässigkeit 
              und Sicherheit für alle professionelle Anforderungen im Enterprise Bereich. 
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Hat der datarhei Restreamer etwas mit restream.io zu tun?"
          description="datarhei.com Produkte und datarhei Restreamer hat keinen Bezug zu restream.io. Der Webservice restream.io hat sich 
          einige Jahre nach datarhei gegründet. Das kann man bei GitHub im Repo und dem Registrierdatum der Domains www.datarhei.com und 
          datarhei.org und .io sehen."
        />
        <AccordionBox
          titel="Funktioniert der Streaming-Server datarhei Restreamer mit OBS?"
          description="OBS mit dem Restreamer zu nutzen, ist mit der internen RTMP-Server-Funktion ganz einfach. OBS dient als Sender für 
          den RTMP-Stream und der Restreamer als Empfänger. Wir haben eine Schnellanleitung auf unserem YouTube Kanal. Die Konfiguration 
          ist mit wenigen Klicks möglich. Das Beste daran: Die Funktion kann für den Empfang beliebiger RTMP-fähiger Videoquellen genutzt 
          werden. OBS ist nur ein von sehr vielen Möglichkeiten für eine sinnvolle Anwendung des RTMP-Servers im Restreamer. "
        />
        <AccordionBox
          titel="Wie viele Schritte werden bei der Integration von einem Live-Stream auf eine Social Media Plattform benötigt?"
          description="Mit dem datarhei Restreamer kann ein Videosignal an jede Streaming-Plattform übertragen werden. Für das Social 
          Streaming stehen Publication Services bereit. Je nach Plattform sind nur 2 bis 5 Klicks notwendig, bis der Live-Stream auf der 
          Social Media Plattform online ist. "
        />
        <AccordionBox
          titel="Ist datarhei Restreamer ein echter Streaming-Server für Live-Streaming?"
          description="Der Restreamer von datarhei.com ist ein echter Streaming-Server der alle Anforderungen für Live-Streaming im Web 
          erfüllt. Unsere Roadmap hat noch einige Punkte offen. Das allerwichtigste für Server ist jedoch Stabilität. Weil unser Server 
          hunderttausendfach getestet ist, erfüllen wir diese Anforderung mit Auszeichnung! Der Streaming-Server läuft und läuft und läuft!"
        />
        <AccordionBox
          titel="Wie kann ich mit dem Streaming-Server kostenlos zu YouTube live streamen?"
          description="Über den Publication Service für YouTube kann mit wenigen Klicks in das Netzwerk von YouTube übertragen werden. Es 
          ist wirklich einfach — und wir meinen wirklich sehr einfach! Kurz gesagt: Publication Service wählen > Streaming Key von YT einfügen 
          >  Stream auf YT starten! Wir haben ein kurzes Video mit einer Schnellanleitung produziert. Du findest das Video mit der Anleitung 
          hier:: https://www.youtube.com/watch?v=dwywuaIQPRg "
        />
        <AccordionBox
          titel="Ist der Streaming-Server als Docker Installation verfügbar?"
          description="Wir empfehlen die Nutzung unseres offiziellen Docker Images. Es ist immer aktuell und ermöglicht eine sehr schnelle 
          Installation. Erfahrene Administratoren können viele verschiedene Environments beim Start setzen.  Mit der Docker Installation ist 
          der Server in Rekordgeschwindigkeit für den Einsatz bereit. Das Beweisvideo für den Rekord haben wir als Schnellanleitung in unserem 
          YouTube Kanal hochgeladen. https://youtu.be/ahawoTRDLEw "
        />
        <AccordionBox
          titel="Kann ich eine Raspberry Pi Kamera Modul mit dem Streaming-Server von datarhei live übertragen?"
          description="Die Raspicam ist das Videomodul für die bekannten Raspberry Pi-Geräte. Nach der Installation im Menü "raspi-config" können 
          alle Kameras im Restreamer für Live-Streaming verwendet werden. Sobald das Kameramodul auf dem Pi aktiv ist, wird es vom Restreamer 
          erkannt und ist sofort bereit für das Live-Streaming über den Streaming-Server. Wenn Du wissen willst, wie einfach es ist, die Raspicam 
          für Live-Streaming auf dem datarhei Restreamer zu nutzen, schaue diese Schnellanleitung, um Dich davon zu überzeugen: https://youtu.be/faH_X1i_Q00"
        />
        <AccordionBox
          titel="Kann ich ohne HTML-Wissen mit dem Videoplayer auf der Landingpage sofort loslegen?"
          description="datarhei Restreamer liefert eine fertige Webseite, für die einfache und schnelle Präsentation des Livestreams. Die Seite ist mit einem 
          eigenen Video Player ausgestattet und sofort startklar. Die index.html kann verlinkt oder verbreitet werden. Die einzelnen Elemente sind über das 
          Menü anpassbar. Fortgeschrittene mit Programmierwissen können über die HTML Code Injection eigene CSS Styles und interaktive Widgets einbauen."
        />
        <AccordionBox
          titel="Wie kann ich HDMI Geräte mit dem Streaming Server verbinden?"
          description="Die Nutzung von HDMI Video ist möglich. Damit HDMI erkannt wird, muss der datarhei Restreamer nativ installiert sein. Die native Installation 
          wird zum Start des Restreamer V2 noch nicht offiziell von uns unterstützt — ist aber schon möglich. Sobald wir offizielle native Installation unterstützen 
          findest Du eine Schnellanleitung auf unserem YouTube Kanal und im Handbuch. Folge am besten unserem Twitter Kanal oder abonniere unseren Newsletter, um 
          die Meldung nicht zu verpassen!"
        />
        <AccordionBox
          titel="Kann ich GPU Encoding mit dem Streaming-Server Restreamer nutzen?"
          description={
            <React.Fragment>
              GPU-Encoding ist eine tolle Sache und wird vom datarhei Restreamer im Experten-Modus unterstützt. Es gibt verschiedene interessante Anwendungen für 
              GPU-Encoding. Der Grafikprozessor (GPU) übernimmt rechenintensive Aufgaben und entlastet dadurch den Hauptprozessor (CPU). Die Funktion wird direkt 
              im Restreamer über den Core mit FFmpeg angesteuert. Die freigewordene Prozessorzeit durch die GPU kann für andere Aufgaben verwendet werden. Wir 
              haben eine Schnellanleitung auf unserem datarhei YouTube Kanal, die eine von vielen Anwendungen mit einer Raspberry PI GPU zeigt.<br />
              <br />{" "}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/yPy3ElSkoSA"
                title="quickstart GPU encoding streaming server"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Ist der kommerzielle Einsatz des Streaming Servers erlaubt?"
          description="Mach mit deinem Restreamer, was du willst. Alles ist erlaubt — private und auch kommerzielle Nutzung. Wenn deine Firma ein erfolgreiches 
          oder spannendes Projekte mit dem datarhei Restreamer oder Core umsetzt, kannst du dich gerne bei uns melden und “Hallo!” sagen. Wir freuen uns über 
          jede Referenz und unterstützen Projekte mit einer Fallstudie, einem Backlink auf das Projekt und die Webseite oder mindestens mit einem kleinen Social 
          Media Posting auf Twitter."
        />
        <AccordionBox
          titel="Can I use audio for my live stream on the streaming server?"
          description="Each video channel in the Restreamer is always audio capable. Any audio format can be muxed (mixed). Audio is queried by the assistance system and can also be edited later. If an audio channel without sound is required, it can be assigned silence. This can be necessary for YouTube, for example. The audio channel can, of course, also be deactivated."
        /> */}
      </Container>
    </Stack>
  </Layout>
)

export default IndexPage
